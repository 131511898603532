import '../i18n';

import { BackToTop } from 'material-ui-back-to-top';
import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import OurStoresBody from '../components/ourStores/ourStoresBody';
import React from 'react';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const OurStores = ({ data }) => {
  const { t } = useTranslation();

  const branchStoresData = data.allBranchStores.edges;
  const branchStoresDataSeoKeywords = branchStoresData.map(
    (branchStore) => branchStore.node.store.name,
  );

  return (
    <React.Fragment>
      <SEO
        title={t('seo.page.ourStores.title')}
        description={t('seo.page.ourStores.description')}
        keywords={[
          'shapya',
          'our stores',
          'shopping',
          'delivery',
          'grocery stores',
          'candy stores',
          'bakery stores',
          'meals',
          'meet our stores',
          'food truck',
          'stores',
          'restaurants',
          branchStoresDataSeoKeywords,
        ]}
      />
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <OurStoresBody branchStoresData={branchStoresData} />
      <Footer />
    </React.Fragment>
  );
};

export default OurStores;

export const query = graphql`
  query MyQuery {
    allBranchStores {
      edges {
        node {
          store {
            description
            logo
            name
            storeType {
              name
            }
          }
          originalId
          id
          slug
          address {
            description
            city
            state
          }
        }
      }
    }
  }
`;
